.acpModal .theatre {
    width: 684px;
    height: 600px;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.acpModalBody {
    height: calc(100% - 72px);
}

@media only screen and (max-width: 1200px) {
    .acpModal .theatre {
        width: 100vw !important;
        height: 100vh !important;
        top: 0 !important;
        left: 0 !important;
        transform: none !important;
    }

    .acpModalBody {
        height: 530px;
    }
}

.acpBodyInnerWrapper {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0px 40px;
}

.acpInputLabel {
    margin-bottom: 2px;
    width: 100%;
    text-align: left;
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.99px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #484848
}

.acpDropdown {
    background: #FFFFFF !important;
    border: 1px solid #929292 !important;
    border-radius: 4px !important;
    height: 48px;
    padding: 0px;
    width: 100%;
}

.acpDropdown .ant-select-clear {
    margin-top: -12px;
    margin-right: 8px;
}

.acpAddAsNew .ant-checkbox-wrapper {
    height: 100%;
    margin-bottom: 0px !important;
    flex-direction: row !important;
    justify-content: inherit !important;
    align-items: center;
}

.acpAddAsNew .ant-checkbox-wrapper .ant-checkbox {
    top: 0;
}

.acpAddAsNew {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.acpAddButton {
    margin-top: 32px;
    width: 50%;
}

.acpError {
    width: 100%;
    margin-top: 4px;
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #E00751;
}

.acpLabel {
    width: 100%;
    margin-top: 4px;
    font-family: Noto IKEA Latin;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #767676;
}

.acpGuidelinesContainer {
    height: 242px;
    margin-top: 32px;
}

.acpGuidelinesWrapper {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    background-color: #F5F5F5;
}

.acpGuidelineTitle {
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 700;
    line-height: 21.99px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #111111
}

.acpGuidelineWrapper {
    display: flex;
    gap: 8px;
}

.acpBullet {
    width: 24px;
    height: 24px;
}

.acpGuideline {
    flex: 1;
    font-family: Noto IKEA Latin;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.99px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}